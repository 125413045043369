import React, { useState, useEffect } from 'react';
import { getCompleteDetails } from '../../actions/familyTreeAction';
import LoadingSpinner from '../loader';
import { useLocation } from '@reach/router';

const DetailsOfUser = () => {
    const [rows, setRows] = useState({})
    const [isLoading, setLoader] = useState(false);

    const location = useLocation();
    const url = decodeURI(location.search);
    const params = new URLSearchParams(url);
    const nodeIdFromUrl = params.get('node_id');
    const nodIdNameFromUrl = params.get('node_name');

    useEffect(() => {
        getDetails();
    }, []);
    const getDetails = async () => {
        setLoader(true);
        const response = await getCompleteDetails(nodeIdFromUrl);
        console.log('complete details of user', response);
        setRows(response?.data);
        setLoader(false)
    }
    const details = (
        <>
            <a target="_blank" href={`/family-tree/complete-family-details?node_id=${nodeIdFromUrl}`}><button>Show Complete Family Tree</button></a>
            <h6>User Info :</h6>
            <p>&emsp;name : {nodIdNameFromUrl}</p>
            <p>&emsp;activities by node count : {rows?.activities_by_node_count}</p>
            <p>&emsp;actual count : {rows?.actual_count}</p>
            <p>&emsp;installed count : {rows?.installed_count}</p>
            <br />
            <h6>Registered By Info</h6>
            <p>&emsp;Name : {rows?.registered_by_info?.name} {rows?.registered_by_info?.gautra}</p>
            <p>&emsp;Village : {rows?.registered_by_info?.village}</p>
            <p>&emsp;Node Id : {rows?.registered_by_info?.graph_node_id}</p>
            <p>&emsp;Mobile No. : {rows?.registered_by_info?.registered_mobile}</p>
            <h6>Added By Info</h6>
            <p>&emsp;Name : {rows?.added_by_info?.name} {rows?.added_by_info?.gautra}</p>
            <p>&emsp;Village : {rows?.added_by_info?.village}</p>
            <p>&emsp;Node Id : {rows?.added_by_info?.graph_node_id}</p>
            <p>&emsp;Mobile No. : {rows?.added_by_info?.registered_mobile}</p>
            <br />
        </>
    )

    return <>{isLoading ? <LoadingSpinner message={`Loading Details of ${nodIdNameFromUrl}`} /> : details}</>;
}

export default DetailsOfUser;
